import React, { useEffect, useState, useRef } from 'react';
import './static/style/style.css'
import './static/style/admin.css'
import './static/style/mobile.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import HomePage from "./pages/HomePage";
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Offer from "./pages/Offer";
import SingleProduct from "./pages/SingleProduct";
import CartPage from "./pages/CartPage";
import ShippingAndPaymentPage from "./pages/ShippingAndPaymentPage";
import LoginPage from "./admin/pages/LoginPage";
import PanelPage from "./admin/pages/PanelPage";
import PanelProducts from "./admin/pages/PanelProducts";
import PanelOrders from "./admin/pages/PanelOrders";
import PanelCategories from "./admin/pages/PanelCategories";
import PanelPayment from "./admin/pages/PanelPayment";
import PanelShipping from "./admin/pages/PanelShipping";
import PanelSettings from "./admin/pages/PanelSettings";
import AddProductPage from "./admin/pages/AddProductPage";
import OrderDetails from "./admin/pages/OrderDetails";
import TYPage from "./pages/TYPage";
import {getAllCategories} from "./helpers/categoryFunctions";
import convertToURL from "./helpers/convertToURL";
import PanelBlog from "./admin/pages/PanelBlog";
import AddPostPage from "./admin/pages/AddPostPage";
import BlogPage from "./pages/BlogPage";
import SinglePostPage from "./pages/SinglePostPage";
import AboutUsPage from "./pages/AboutUsPage";

import "aos/dist/aos.css";
import AOS from 'aos';
import PanelAboutUs from "./admin/pages/PanelAboutUs";
import ContactPage from "./pages/ContactPage";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PanelOthers from "./admin/pages/PanelOthers";
import PanelCoupons from "./admin/pages/PanelCoupons";
import CustomMenu from "./pages/CustomMenu";
import PanelDelivery from "./admin/pages/PanelDelivery";
import PanelHomepage from "./admin/pages/PanelHomepage";
import PanelSlider from "./admin/pages/PanelSlider";
import {getAllProducts} from "./helpers/productFunctions";
import modalPl from './static/img/popup-pl.jpg';
import modalEn from './static/img/popup-en.jpg';
import StartPage from "./components/StartPage";
import axios from "axios";
import settings from "./admin/helpers/settings";

ReactGA.initialize('G-2YV1L21QB9');
ReactGA.pageview(window.location.pathname + window.location.search);

const LangContext = React.createContext(null);

function App() {
    const [loading, setLoading] = useState(true);
    const [polandAvailable, setPolandAvailable] = useState(false);
    const [deliveryRegion, setDeliveryRegion] = useState(-1);
    const [categories, setCategories] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [langIndex, setLangIndex] = useState(localStorage.getItem('langIndex') ? parseInt(localStorage.getItem('langIndex')) : 0);

    let modal = useRef(null);

    const content = [
        {
            cart: 'Koszyk',
            homepageHeader: 'Każde zamówienie pakujemy z wielką starannością i załączamy specjalną dedykację dla Ciebie!',
            homepageCallToAction: 'Zamów teraz',
            phone: 'Telefon',
            orderNow: 'Zadzwoń!',
            menu: ['Strona główna', 'O nas', 'Menu', 'Kontakt'],
            footerCopyright: 'Wszelkie prawa zastrzeżone',
            footerMenu: ['Polityka prywatności', 'Regulamin', 'Kontakt'],
            cartHeader: 'Podsumowanie koszyka',
            cartCols: ['Nazwa produktu', 'Opcja', 'Rozmiar', 'Ilość', "Wartość", 'Działania'],
            cartSum: 'Łącznie do zapłaty',
            cartBtn: 'Przejdź dalej',
            checkoutHeader: 'Wpisz swoje dane i dokończ zamówienie',
            checkoutSubheaders: ['Wybierz dzień dostawy', 'Wybierz godzinę dostawy', 'Dane do dostawy', 'Pozostałe informacje'],
            checkoutForm: ['Twoje imię', 'Twoje nazwisko', 'Adres e-mail', 'Numer telefonu', 'Miejscowość', 'Kod pocztowy', 'Ulica, numer domu/mieszkania, piętro', 'Kod do domofonu (opcjonalnie)'],
            checkoutCheckboxes: ['Odbiór osobisty', 'Płatność przy odbiorze', 'Płatność gotówką', 'Płatność kartą', 'Mam kupon rabatowy', 'Wstążka z dedykacją', 'Chcę otrzymać fakturę'],
            checkoutCompanyForm: ['Nazwa firmy', 'NIP', 'Miejscowość', 'Kod pocztowy', 'Ulica, numer budynku'],
            checkoutDiscountCodeInput: 'Tu wpisz swój kupon',
            checkoutDiscountCodeBtn: 'Dodaj kupon',
            checkoutDiscountCodeError: 'Podany kupon rabatowy nie istnieje',
            checkoutDiscountCodeSuccess: ['Kupon', 'Zniżka'],
            checkoutFromTo: ['Od kogo', 'Dla kogo'],
            checkoutTextarea: 'Komentarz do zamówienia (opcjonalnie)',
            checkoutDelivery: 'Dostawa',
            checkoutBtn: 'Złóż zamówienie',
            checkoutBtnPayment: 'Przejdź do płatności',
            checkoutBackToCart: 'Powrót do koszyka',
            offerSubheader: 'Wybierz idealny zestaw skomponowany do Twoich potrzeb.',
            offerBtn: 'Zamów teraz',
            availableSizes: 'Wybierz rozmiar',
            availableOptions: 'Dostępne opcje',
            addToCart: 'Dodaj do koszyka',
            meatVersion: 'Mieszana',
            vegeVersion: 'Wegetariańska',
            allergens: 'Alergeny',
            addedToCart: "Produkt został dodany do koszyka",
            halfBoxError: 'Połowa boxa została dodana do koszyka. Uzupełnij swój box o drugą połówkę.',
            cartHalfBoxError: 'Musisz skompletować całkowitą liczbę boxów',
            continueShopping: 'Kontynuuj zakupy',
            goToCheckout: 'Przejdź do kasy',
            chooseSecondHalf: 'Wybierz drugą połowę',
            aboutUs: 'O nas',
            emptyCart: 'Twój koszyk jest pusty',
            backToShop: 'Wróć do sklepu',
            noDelivery: 'Brak możliwości dostawy na podany adres',
            checkoutMobile: 'Wybierz datę i godzinę zamówienia',
            aboutUsBtn: 'Zobacz dostępne zestawy',
            bestsellers: 'Bestsellery',
            newProducts: 'Nowości',
            ty1: 'Dziękujemy za złożenie zamówienia!',
            ty2: 'Dostarczymy je w wybranym przez Ciebie terminie.',
            ty3: 'Wróć na stronę główną',
            checkbox1: 'Akceptuję',
            checkbox2: 'Regulamin',
            checkbox3: 'Politykę prywatności',
            and: 'i',
            noSlotsAvailable: `W przypadku braku dostępnych slotów dostaw prosimy o kontakt pod numerem: 696-696-995.`,
            errorCode: 'Coś poszło nie tak... Skontaktuj się z nami i przekaż kod błędu:',
            checkboxError: 'Aby przejść dalej, zaakceptuj regulamin i politykę prywatności',
            notConfuse: 'nie mylić z Arkadią',
            nightDelivery1: 'Dostawa odbywa się w godzinach nocnych',
            nightDelivery2: 'Dostawy realizowane są w godzinach nocnych. Ze względów logistycznych nie gwarantujemy dostarczenia zamówienia do konkretnej godziny.',
            nightDelivery3: 'Kod domofonu pomoże dostawcy w bezkontaktowej obsłudze zamówienia.',
            contactForm: ['Imię *', 'Nr telefonu *', 'E-mail *', 'Nazwa firmy'],
            contactFormHeader: 'Wyślij zapytanie',
            contactFormError: 'Wypełnij wymagane pola',
            contactFormSuccess: 'Dziękujemy za zgłoszenie! Odezwiemy się do Ciebie tak szybko, jak to możliwe.'
        },
        {
            cart: 'Cart',
            homepageHeader: 'Each order is packed with special care by our team and we\'ll even include a message from you!',
            homepageCallToAction: 'Order now',
            phone: 'Phone',
            orderNow: 'Call us!',
            menu: ['Home', 'About us', 'Menu', 'Contact'],
            footerCopyright: 'All rights reserved',
            footerMenu: ['Privacy policy', 'Terms of service', 'Contact'],
            cartHeader: 'Cart',
            cartCols: ['Product name', 'Option', 'Size', 'Quantity', "Total", 'Edit'],
            cartSum: 'Total',
            cartBtn: 'Next',
            checkoutHeader: 'Fill the data',
            checkoutSubheaders: ['The day of delivery', 'Delivery slot', 'Delivery details', 'Additional information'],
            checkoutForm: ['First name', 'Last name', 'E-mail', 'Phone number', 'City', 'Postal code', 'Street, building, flat', 'Intercom code (optional)'],
            checkoutCheckboxes: ['Collection', 'Pay at the delivery', 'Cash', 'Card', 'Discount code', 'Dedication', 'Get an invoice'],
            checkoutCompanyForm: ['Company name', 'NIP', 'City', 'Postal code', 'Street, building, flat'],
            checkoutDiscountCodeInput: 'Your discount code',
            checkoutDiscountCodeBtn: 'Add discount code',
            checkoutDiscountCodeError: 'Discount code does not exists',
            checkoutDiscountCodeSuccess: ['Code', 'Discount'],
            checkoutFromTo: ['From', 'To'],
            checkoutTextarea: 'Additional comment (optionally)',
            checkoutDelivery: 'Delivery',
            checkoutBtn: 'Order',
            checkoutBtnPayment: 'Go to payment',
            checkoutBackToCart: 'Back to cart',
            offerSubheader: 'Choose your perfect box',
            offerBtn: 'Order now',
            availableSizes: 'Available sizes',
            availableOptions: 'Available options',
            addToCart: 'Add to cart',
            meatVersion: 'Mix',
            vegeVersion: 'Vege',
            allergens: 'Allergens',
            addedToCart: "Produkt added to cart",
            halfBoxError: 'First half of the box has been added to cart. Fulfill your box with second half.',
            cartHalfBoxError: 'You have to complete full box',
            continueShopping: 'Continue',
            goToCheckout: 'Checkout',
            chooseSecondHalf: 'Choose second half',
            aboutUs: 'About us',
            emptyCart: 'Your cart is empty',
            backToShop: 'Back to shop',
            noDelivery: 'Sorry, we are not deliver to your location',
            checkoutMobile: 'Choose day and hour of delivery',
            aboutUsBtn: 'Choose your box',
            bestsellers: 'Bestsellers',
            newProducts: 'New',
            ty1: 'Thank you for your order!',
            ty2: 'It will be delivered in time you choose.',
            ty3: 'Back to homepage',
            checkbox1: 'I agree to',
            checkbox2: 'Terms of service',
            checkbox3: 'Privacy policy',
            and: 'and',
            noSlotsAvailable: `In case no slots available, please contact us: 696-696-995`,
            errorCode: 'Something went wrong... Please contact us and tell us error code:',
            checkboxError: 'Please accept our Terms of Service and Privacy Policy',
            notConfuse: 'Do not confuse with Arkadia',
            nightDelivery1: 'Deliveries take place during night time',
            nightDelivery2: 'Deliveries are made at night. Regarding logistics, we do not deliver orders until a specific time.',
            nightDelivery3: 'The intercom code helps the delivery person to interrupt orders without contact.',
            contactForm: ['Name *', 'Phone number *', 'Email *', 'Company name'],
            contactFormHeader: 'Send an inquiry',
            contactFormError: 'Fill out requested fields',
            contactFormSuccess: 'Thank you for your inquiry! We will reach you out as soon as possible.'
        }
    ];

    useEffect(() => {
        axios.get(`${settings.API_URL}/shipping/get-info`)
            .then(res => {
                const result = res.data.result[0];
                if(result) {
                    if(result.is_poland_on) {
                        setPolandAvailable(true);
                    }
                    else {
                        // Only Warsaw available
                        setDeliveryRegion(0);
                    }
                }
            });

        try {
            const deliveryRegionFromLocalStorage = localStorage.getItem('delivery');

            if(deliveryRegionFromLocalStorage) {
                const deliveryObject = JSON.parse(deliveryRegionFromLocalStorage);
                const isCurrent = new Date(deliveryObject.expire) > new Date();

                if(isCurrent) {
                    if(deliveryObject.value === 'poland') {
                        setDeliveryRegion(1);
                    }
                    else if(deliveryObject.value === 'warsaw') {
                        setDeliveryRegion(0);
                    }
                    else {
                        setDeliveryRegion(-1);
                    }

                    setLoading(false);
                }
                else {
                    setDeliveryRegion(-1);
                    localStorage.removeItem('delivery');
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                setDeliveryRegion(-1);
            }
        }
        catch(e) {
            console.log(e);
            setDeliveryRegion(-1);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('langIndex', langIndex.toString());
    }, [langIndex]);

    useEffect(() => {
       AOS.init({
           delay: 200,
           duration: 500
       });

       getAllCategories()
           .then(res => {
               setCategories(res.data.result);
           });
    }, []);

    useEffect(() => {
        // Remove old products from cart
        getAllProducts()
            .then(res => {
                if(res?.data?.result) {
                    const products = res.data.result?.filter((item) => {
                        return !item.hidden;
                    }).map(item => (item.id));

                    const cart = localStorage.getItem('sec-cart');

                    if(cart) {
                        try {
                            let cartJSON = JSON.parse(cart);

                            cartJSON = cartJSON.filter((item) => {
                                return products.includes(item.id);
                            });

                            if(cartJSON.length) {
                                localStorage.setItem('sec-cart', JSON.stringify(cartJSON));
                            }
                            else {
                                localStorage.removeItem('sec-cart');
                            }
                        }
                        catch(e) {
                            console.log(e);
                        }
                    }
                }
            });
    }, []);

    const closeModal = () => {
        modal.current.style.display = 'none';
    }

    useEffect(() => {
        const checkUrlForPanel = () => {
            const currentUrl = window.location.href;
            if(currentUrl.includes('panel') || currentUrl.includes('admin')) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        };

        checkUrlForPanel();
    }, []);

  return (<>
        <LangContext.Provider value={{
            content: content[langIndex],
            langIndex,
            changeLanguage: setLangIndex,
            deliveryRegion,
            setDeliveryRegion, polandAvailable
        }}>
            <div className="App">
                {/*<div className="modal"*/}
                {/*     onClick={closeModal}*/}
                {/*     ref={modal}>*/}
                {/*    <button className="modal__closeBtn">*/}
                {/*        &times;*/}
                {/*    </button>*/}

                {/*    <img className="img" src={langIndex ? modalEn : modalPl} alt="modal" />*/}
                {/*</div>*/}

                {((!loading) && (polandAvailable) && (deliveryRegion === -1) && (!isAdmin)) && <StartPage />}

                <Router>
                    <Switch>
                        {/* WEBSITE ROUTES */}
                        <Route exact path="/">
                            <HomePage />
                        </Route>
                        <Route path="/dziekujemy">
                            <TYPage />
                        </Route>
                        <Route path="/oferta">
                            <Offer type="oferta" />
                        </Route>
                        <Route path="/blog">
                            <BlogPage />
                        </Route>
                        <Route path="/wpis">
                            <SinglePostPage />
                        </Route>
                        <Route path="/o-nas">
                            <AboutUsPage />
                        </Route>
                        <Route path="/kontakt">
                            <ContactPage />
                        </Route>
                        <Route path="/regulamin">
                            <TermsOfService />
                        </Route>
                        <Route path="/polityka-prywatnosci">
                            <PrivacyPolicy />
                        </Route>

                        {/* CATEGORIES */}
                        {categories?.map((item, index) => {
                            if(index === 2) {
                                /* Banquet menu */
                                return <Route key={index} path="/przekaski-bankietowe">
                                    <CustomMenu />
                                </Route>
                            }
                            else {
                                /* Normal menu */
                                return <Route key={index} path={"/" + convertToURL(item.name)}>
                                    <Offer type={item.name} />
                                </Route>
                            }
                        })}

                        {/* Page for all products */}
                        <Route path="/produkt">
                            <SingleProduct />
                        </Route>

                        {/* Shipping and payment */}
                        <Route path="/dostawa-i-platnosc">
                            <ShippingAndPaymentPage />
                        </Route>

                        {/* Cart */}
                        <Route path="/koszyk">
                            <CartPage />
                        </Route>

                        {/* ADMIN ROUTES */}
                        <Route exact path='/admin'>
                            <LoginPage />
                        </Route>
                        <Route exact path="/panel">
                            <PanelPage />
                        </Route>
                        <Route path="/panel/produkty">
                            <PanelProducts />
                        </Route>
                        <Route path="/panel/zamowienia">
                            <PanelOrders />
                        </Route>
                        <Route path="/panel/kategorie">
                            <PanelCategories />
                        </Route>
                        <Route path="/panel/platnosci">
                            <PanelPayment />
                        </Route>
                        <Route path="/panel/wysylka">
                            <PanelShipping />
                        </Route>
                        <Route path="/panel/ustawienia">
                            <PanelSettings />
                        </Route>
                        <Route path="/panel/blog">
                            <PanelBlog />
                        </Route>
                        <Route path="/panel/o-nas">
                            <PanelAboutUs />
                        </Route>
                        <Route path="/panel/kupony">
                            <PanelCoupons />
                        </Route>
                        <Route path="/panel/dostawa">
                            <PanelDelivery />
                        </Route>
                        <Route path="/panel/pozostale">
                            <PanelOthers />
                        </Route>
                        <Route path="/panel/strona-glowna">
                            <PanelHomepage />
                        </Route>
                        <Route path="/panel/slider">
                            <PanelSlider />
                        </Route>

                        {/* Add content pages */}
                        <Route path="/panel/dodaj-produkt">
                            <AddProductPage />
                        </Route>
                        <Route path="/panel/dodaj-wpis">
                            <AddPostPage />
                        </Route>

                        {/* Order details */}
                        <Route path="/panel/szczegoly-zamowienia">
                            <OrderDetails />
                        </Route>
                    </Switch>
                </Router>
            </div>
        </LangContext.Provider>
      </>
  );
}

export default App;
export { LangContext }
