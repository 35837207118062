import logo from '../static/external/img/logo.png'

const settings = {
    API_URL: "https://brunchbox.pl",
    // API_URL: "http://localhost:5000",
    logo: logo,
    homepage: "https://brunchbox.pl"
}

export default settings;
