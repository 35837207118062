import React, {useContext} from 'react';
import {LangContext} from "../App";

const CartNotValidForRegionModal = ({closeModal}) => {
    const { content } = useContext(LangContext)

    return <div className="modal"
                onClick={closeModal}>
        <button className="modal__closeBtn">
            &times;
        </button>

        <div className={'modal__inner'}>
            <p className={'modal__inner__text'}>
                Niestety, nie dowozimy produktów, które masz w koszyku w podane miejsce. Spróbuj usunąć zawartość
                koszyka, przejść do menu i spróbować dodać dostępne boxy.
            </p>

            <a href={'/koszyk'} className={'btn--showMap--small'}>
                {content.cart}
            </a>
        </div>
    </div>
};

export default CartNotValidForRegionModal;
