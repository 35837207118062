const deliveryHours = [
    { start: 10, end: 11, available: 1 },
    { start: 11, end: 12, available: 1 },
    { start: 12, end: 13, available: 1 },
    { start: 13, end: 14, available: 1 },
    { start: 14, end: 15, available: 1 },
    { start: 15, end: 16, available: 1 }
];

const deliverySchedule = [
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    },
    {
        day: "",
        hours: deliveryHours
    }
]

export default deliverySchedule;
