import React, {useContext, useState} from 'react';
import {LangContext} from "../App";
import axios from "axios";
import Loader from "react-loader-spinner";

const ContactForm = () => {
    const { content } = useContext(LangContext);

    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [success, setSuccess] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = () => {
        setSubmitted(true);

        if(message && name && email && phoneNumber) {
            setIsSending(true);

            axios.post("https://brunchbox.pl/order/send-form", {
                message, name, email, phoneNumber, company
            })
                .then((res) => {
                    console.log(res);
                   setSuccess(true);
                   setIsSending(false);
                })
                .catch((err) => {
                    setIsSending(false);
                    alert(`${content?.errorCode} 1`);
                });
        }
        else {
            alert(content?.contactFormError);
        }
    }

    return <div className={'contactForm'}>
        <textarea
            className={submitted && !message ? "shippingAndPayment__textArea shippingAndPayment__textArea--contactForm shippingAndPayment--error" : "shippingAndPayment__textArea shippingAndPayment__textArea--contactForm"}
            name="message"
            value={message}
            onChange={(e) => { setMessage(e.target.value); }}
            placeholder={content.contactFormHeader} />
        <div className={'flex flex-mobile'}>
            <label className="shippingAndPayment__label label-25">
                <input className={submitted && !name ? "shippingAndPayment__input shippingAndPayment--error" : "shippingAndPayment__input"}
                       name="name"
                       value={name}
                       onChange={(e) => { setName(e.target.value); }}
                       placeholder={content?.contactForm[0]}
                       type="text" />
            </label>
            <label className="shippingAndPayment__label label-25">
                <input className={submitted && !phoneNumber ? "shippingAndPayment__input shippingAndPayment--error" : "shippingAndPayment__input"}
                       name="phoneNumber"
                       value={phoneNumber}
                       onChange={(e) => { setPhoneNumber(e.target.value); }}
                       placeholder={content?.contactForm[1]}
                       type="text" />
            </label>
            <label className="shippingAndPayment__label label-25">
                <input className={submitted && !email ? "shippingAndPayment__input shippingAndPayment--error" : "shippingAndPayment__input"}
                       name="email"
                       value={email}
                       onChange={(e) => { setEmail(e.target.value); }}
                       placeholder={content?.contactForm[2]}
                       type="text" />
            </label>
            <label className="shippingAndPayment__label label-25">
                <input className={"shippingAndPayment__input"}
                       name="company"
                       value={company}
                       onChange={(e) => { setCompany(e.target.value); }}
                       placeholder={content?.contactForm[3]}
                       type="text" />
            </label>
        </div>

        {isSending ? <div className={'contactForm__loader'}>
            <Loader
                type="Oval"
                color="#000"
                width={30}
                height={30}
            />
        </div> : (success ? <h3 className={'contactForm__success'}>
            {content?.contactFormSuccess}
        </h3> : <button className={'contactForm__btn'}
                        onClick={handleSubmit}>
            {content?.contactFormHeader}
        </button>)}
    </div>
};

export default ContactForm;
